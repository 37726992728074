<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            INFORM
        </v-card-title>
        <v-card-text>
            <v-form v-model="valid">
                <v-row no-gutters>
                    <v-col cols="12" xl="6" lg="6" md="6" class="pl-0">
                        <v-select
                            v-model="inform.type"
                            :items="types"
                            label="Type *"
                            prepend-icon="mdi-calendar-month-outline"
                            :rules="[rules.required]"
                            :disabled="!createForm"
                        >
                        </v-select>
                    </v-col>
                    <v-col
                        v-if="inform.type == 'monthly'"
                        cols="12"
                        xl="6"
                        lg="6"
                        md="6"
                        class="pl-1"
                    >
                        <v-select
                            v-model="monthlyDay"
                            :items="[monthlyDay]"
                            label="Day *"
                            prepend-icon="mdi-calendar-today"
                            disabled
                        />
                    </v-col>
                    <v-col v-else cols="12" xl="6" lg="6" md="6" class="pl-1">
                        <v-select
                            v-model="day"
                            :items="days"
                            label="Day *"
                            prepend-icon="mdi-calendar-today"
                            :rules="[rules.required]"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" xl="6" lg="6" md="6" class="pl-0">
                        <v-autocomplete
                            multiple
                            :search-input.sync="searchEmails"
                            @change="searchEmails = ''"
                            v-model="inform.emails"
                            :items="users"
                            item-text="name"
                            item-value="email"
                            label="Emails"
                            prepend-icon="mdi-account"
                            small-chips
                            :rules="[rules.required, rules.emails]"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        xl="6"
                        lg="6"
                        md="6"
                        :class="'d-flex align-center justify-center'"
                    >
                        <v-icon>
                            mdi-clock-time-four-outline
                        </v-icon>
                        <div class="ml-2">
                            <p class="mb-0" :style="{ 'font-size': '12px' }">
                                Time *
                            </p>
                            <vue-timepicker
                                manual-input
                                v-model="inform.time"
                                input-width="200px"
                                drop-direction="up"
                                :style="{ border: '1px solid #939393' }"
                                class="mb-5"
                            ></vue-timepicker>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                rounded
                color="primary"
                @click="save"
                :loading="loading"
                :disabled="!valid || !inform.time"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import { objDiff } from '@/helpers/objDiff.js'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
    name: 'ForecastReportDialog',
    props: {
        settingId: {
            type: String,
            required: true,
        },
        inform: {
            type: Object,
        },
        originalInform: {
            type: Object,
            default: () => {},
        },
        createForm: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        VueTimepicker,
    },
    data: () => ({
        searchEmails: null,
        loading: false,
        users: [],
        valid: false,
        emails: [],
        hours: '',
        minutes: '',
        rules: {
            required: v => !!v || 'Required',
            emails: v =>
                v.length > 0 ||
                v.find(email => !/.+@.+\..+/.test(email.text)) ||
                'Required',
            email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
            number: v =>
                typeof Number(v.replaceAll('.', '')) == 'number' ||
                'The value is not valid.',
        },
        types: ['weekly', 'monthly'],
        days: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ],
        type: null,
        day: null,
        monthlyDay: 'First day of the month',
    }),
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            if (this.inform.day) {
                this.day = this.days[this.inform.day]
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        informDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalInform, this.inform)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('close')
        },
        async generateInform() {
            try {
                this.loading = true
                this.loadingButton = true
                const inform = await API.createForecastInform({
                    report: 'forecast',
                    settingId: this.settingId,
                    type: this.inform.type,
                    emails: this.inform.emails,
                    date: this.inform.time.HH + ':' + this.inform.time.mm,
                    ...(this.inform.type == 'weekly'
                        ? {
                              day: this.days.findIndex(d => d == this.day),
                          }
                        : {}),
                })
                this.$emit('addInform', inform)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.loadingButton = false
            }
        },
        async updateInform() {
            try {
                this.loading = true
                this.loadingButton = true
                const paramsToUpdate = {
                    ...this.informDiff,
                    ...(this.informDiff.emails
                        ? {
                              emails: this.inform.emails,
                          }
                        : {}),
                    ...(this.informDiff.time
                        ? {
                              date:
                                  this.informDiff.time.HH +
                                  ':' +
                                  this.informDiff.time.mm,
                          }
                        : {}),
                }
                delete paramsToUpdate.time
                const inform = await API.updateForecastInform({
                    settingId: this.settingId,
                    informId: this.inform.id,
                    ...paramsToUpdate,
                    ...(this.inform.type == 'weekly'
                        ? {
                              day: this.days.findIndex(d => d == this.day),
                          }
                        : {}),
                })
                this.$emit('replaceInform', inform)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.loadingButton = false
            }
        },
        async save() {
            try {
                if (this.createForm) {
                    await this.generateInform()
                } else {
                    await this.updateInform()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}
</script>

<style scoped></style>
